import { Controller } from "@hotwired/stimulus";
import { toggle as toggleTransition } from "el-transition";
// Connects to data-controller="navigatio"
export default class extends Controller {
  static targets = ["mobileNavMenu"];

  toggle(e) {
    const button = e.currentTarget;
    button.ariaExpanded = !(button.ariaExpanded === "true");

    //  When header is sticky, remove stickiness when mobile nav is expanded
    const header = document.getElementById("site:header");
    if (header) {
      if (header.classList.contains("sticky")) {
        if (button.ariaExpanded === "true") {
          header.classList.add("!relative");
        } else {
          header.classList.remove("!relative");
        }
      }
    }
    // Enable scroll for mobile nav only
    // Note: if a user expands viewport after toggling mobile nav, body is no longer scrollable.
    const editorPreviewContainerBody = document.getElementById("preview-inner-container-body");
    if (editorPreviewContainerBody) {
      editorPreviewContainerBody.classList.toggle("!overflow-hidden");
    } else {
      document.body.classList.toggle("overflow-hidden");
    }

    toggleTransition(this.mobileNavMenuTarget);

    // Animate hamburger icon
    Array.from(button.children).forEach((child, index) => {
      if (index === 0) {
        child.classList.toggle("rotate-45");
        child.classList.toggle("translate-y-[8px]");
      } else if (index === 1) {
        child.classList.toggle("-rotate-45");
      } else {
        child.classList.toggle("-rotate-45");
        child.classList.toggle("translate-y-[-8px]");
      }
    });
  }
}
