import { Controller } from "@hotwired/stimulus";
import { enter, leave, toggle } from "el-transition";
// Copy pasted from @stimulus-components/dropdown
// Connects to data-controller="navigation-dropdown"
function isTouchDevice() {
  return "ontouchstart" in window || navigator.maxTouchPoints > 0;
}

export default class extends Controller {
  static targets = ["button", "menu", "desktopInnerMenu"];

  connect() {
    if (this.hasDesktopInnerMenuTarget) {
      // Add data action to last anchor in dropdown to close on blur
      if (this.desktopInnerMenuTarget.lastElementChild?.firstElementChild) {
        this.desktopInnerMenuTarget.lastElementChild.firstElementChild.dataset.action = "blur->navigation-dropdown#hideLast";
      }
    }
  }

  toggle(e) {
    const button = e.currentTarget;
    button.ariaExpanded = button.ariaExpanded === "false" ? "true" : "false";
    toggle(this.menuTarget);
  }

  hide(event) {
    if (!this.element.contains(event.target) && !this.desktopInnerMenuTarget.classList.contains("hidden")) {
      this.buttonTarget.ariaExpanded = false;
      leave(this.menuTarget);
    }
  }

  hideLast() {
    this.buttonTarget.ariaExpanded = false;
    leave(this.menuTarget);
  }

  mouseenter(e) {
    if (isTouchDevice()) return;

    if (this.buttonTarget.ariaExpanded === "false") {
      this.buttonTarget.ariaExpanded = true;
      enter(this.menuTarget);
    }
  }

  mouseleave(event) {
    if (isTouchDevice()) return;

    if (this.element.contains(event.relatedTarget)) return;

    if (this.buttonTarget.ariaExpanded === "true") {
      this.buttonTarget.ariaExpanded = false;
      leave(this.menuTarget);
    }
  }

  focus() {
    if (isTouchDevice()) return;

    if (this.buttonTarget.ariaExpanded === "false") {
      this.buttonTarget.ariaExpanded = true;
      enter(this.menuTarget);
    }
  }
}
